import * as React from "react";
import PatientLayout from "../components/patient/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";

const TermsAndConditionsPage = () => {
  return (
    <PatientLayout pageClass="patient-contact-us">
      <section className="btm-spacer">
        <Row>
          <Col xs={12}>
            <h1 className="first interior-first">Terms of Use</h1>
            <p className="text-bold">The information provided on this Internet Web site ("Site") is for general informational and educational purposes only. Please read and review these Terms of Use carefully before accessing or using this Site. By accessing or using this Site, you acknowledge that you have read, understood and agreed to these Terms of Use. If you do not agree to the Terms of Use, do not access or use the site. "Purdue," as used in these Terms of Use refers to Purdue Pharma L.P., a participating company in a network of independent associated companies.</p>
            <ol className="number-list">
              <li>COPYRIGHT AND TRADEMARK NOTICE
                <ol>
                  <li className="no-list no-btm-spacer">The entire content of this Site is fully protected under all applicable intellectual property laws, including without limitation copyright, trade dress and trademark laws. Users may print copies of the content of this Site for their personal noncommercial use only. Further printing, copying, redistribution or publication of any part of this Site is strictly prohibited, unless expressly permitted as stated within these Terms of Use or as stated within this Site.</li>
                </ol>
              </li>
              <li>RESTRICTION ON USING THE CONTENT OF THIS WEBSITE
                <ol>
                  <li className="no-list">Purdue makes no representation regarding the availability, the accuracy, or the completeness of the content of this Site. The Site contains information about products that may or may not be available in a particular country or region of the world, may be available under different trademarks in different countries and, where applicable, may be approved by government regulatory authority for sale or use with different indications and restrictions in different countries.</li>
                  <li className="no-list">This Site may contain, from time to time, information related to various health, medical and fitness conditions and their treatment. For non-healthcare providers, such information is not intended to be a substitute for the advice provided by a physician or other medical professional. You should not use the information contained herein for diagnosing a health or fitness problem or disease. In any event, users of this Site should always consult a doctor and/or other medical professional with respect to health concerns.</li>
                  <li className="no-list">While Purdue uses reasonable efforts to include accurate and up-to-date information in its Site, Purdue makes no warranties or representations as to its accuracy. Purdue assumes no liability or responsibility for any errors or omissions in the content of its Site. This Site may provide links or references to other Web sites not affiliated with Purdue. Such links to other Sites are provided only as a convenience to users of this Site. Purdue has not reviewed all of the Sites that may be linked to its Site, and is not responsible for the content of any other Site linked to this Site. Linking to any pages off this Site is at your own risk. Purdue shall not be liable for any damages or injury arising from users' access to such Sites.</li>
                  <li className="no-list no-btm-spacer">If in the event the Site provides visitors with an opportunity to post messages, you shall not post or transmit any unlawful, threatening, libelous, defamatory, obscene, inflammatory, pornographic or profane material or material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or would otherwise violate the law. Purdue shall not be responsible for content posted to its site by third parties. Purdue respects the privacy of its Site users. Please refer to Purdue's Privacy Policy which explains how information that is disclosed on this Site will be handled.</li>
                </ol>
              </li>
              <li>INDEMNIFICATION BY SITE USER
                <ol>
                  <li className="no-list text-bold no-btm-spacer">IN EXCHANGE FOR ACCESSING AND/OR USING THIS SITE, SITE USER AGREES TO INDEMNIFY, DEFEND AND HOLD HARMLESS PURDUE, ITS ASSOCIATED COMPANIES, AND ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS AND THIRD PARTY PARTNERS FROM AND AGAINST ALL LOSSES, EXPENSES, DAMAGES AND COSTS, INCLUDING REASONABLE ATTORNEYS' FEES, RESULTING FROM ANY VIOLATION BY YOU OF THESE TERMS OF USE.</li>
                </ol>
              </li>
              <li>LIMITATION OF LIABILITY
                <ol>
                  <li className="no-list no-btm-spacer">Purdue provides the contents of its Site for informational purposes and for your general interest and entertainment only. By using the Site you hereby agree not to rely on any of the information contained herein. <span className="text-bold">UNDER NO CIRCUMSTANCES SHALL PURDUE BE LIABLE FOR YOUR RELIANCE ON ANY SUCH INFORMATION NOR SHALL PURDUE BE LIABLE FOR DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL, INDIRECT OR PUNITIVE DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS IN THIS SITE OR THE MATERIALS IN ANY SITE WHICH MAY BE LINKED TO THIS SITE, EVEN IF PURDUE OR A PURDUE AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL PURDUE'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION WHETHER THE CAUSE OF ACTION IS IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THIS SITE</span>.</li>
                </ol>
              </li>
              <li>DISCLAIMER OF WARRANTIES
                <ol>
                  <li className="no-list text-bold no-btm-spacer">THE MATERIALS IN THIS SITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, PURDUE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. PURDUE DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PURDUE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS IN THESE SITES IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT PURDUE) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</li>
                </ol>
              </li>
              <li>JURISDICTIONAL ISSUES/EXPORT RESTRICTIONS
                <ol>
                  <li className="no-list">This Site is controlled and operated by Purdue from its offices within the State of Connecticut, United States of America. Although the Internet is global in nature, the laws regulating the manner in which pharmaceutical companies convey medical or product information are not. Each country has its own set of rules, regulations and laws. As a result, information intended for dissemination in a particular country may not be appropriate for use outside that country. The information on this Site is designed to comply with the laws of the United States. The information on this Site is intended for access and use by U.S. residents only. These Terms of Use shall be governed by and construed in accordance with the laws of the State of Connecticut, without giving effect to any principles of conflicts of law. If any provision of these Terms of Use shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions. This is the entire Agreement between the parties relating to the subject matter herein.</li>
                  <li className="no-list">This Site may link to other Sites produced by companies related to Purdue, some of which are outside the U.S. Those Sites may have information that is appropriate only to the particular originating country or region where the Site is based. You should not construe anything on the Site as a promotion or solicitation for any product or for the use of any product that is not authorized by the laws and regulations of the country where you are located. Purdue makes no representation that materials in the Site are appropriate or available for use in other locations. Those who choose to access this Site from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.</li>
                  <li className="no-list no-btm-spacer">Software from this Site is further subject to United States export controls. No software from this Site may be downloaded or otherwise exported or re-exported (i) into (or to a national or resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria or any other country subject to United States export control restrictions; or (ii) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Commerce Department's Table of Denial Orders. By downloading or using the Software, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.</li>
                </ol>
              </li>
              <li>CAREER AND/OR BUSINESS OPPORTUNITIES
                <ol>
                  <li className="no-list">The Site displays information about job postings ("Current Openings") for informational purposes only. This information is subject to change without notice, and in displaying such information Purdue makes no representation or warranty that the information contained on the Site will be timely or free of errors and omissions.</li>
                  <li className="no-list">It is the intent of Purdue to leave you with absolute control over the decision to forward your personal information or resume to the company. It is your choice to give Purdue your personal information via the Internet, Fax or Mail.</li>
                  <li className="no-list">Under no circumstances, should you use this Site or any of the fax and mailing addresses on the Site to send Purdue confidential or proprietary information. Purdue does not want to receive such confidential or proprietary information. Any information deemed not personally identifiable and deemed not subject to our Privacy Policy, including questions, comments, ideas, and suggestions shall be deemed to be non-confidential, and Purdue shall be free to disclose and/or use the same, without obligations whatsoever towards you, for any purpose whatsoever and through any means whatsoever.</li>
                  <li className="no-list no-btm-spacer">Although accessible by others, the intent of Purdue in displaying Current Openings on this Site is to display employment opportunities within the United States of America. You should not construe anything on the Site as a promotion or solicitation for employment not authorized by the laws and regulations of the country where you are located.</li>
                </ol>
              </li>
              <li>LINKING POLICY
                <ol>
                  <li className="no-list">Links Out — This Site may provide links or references to other Web sites not affiliated with Purdue. Purdue has not reviewed the content of Web sites that may be linked to its Site, makes no representations about the content of the Web sites, and is not responsible for the content of any other Web site linked to this Site. Linking to any pages off this Site is at your own risk. Purdue shall not be liable for any damages or injury arising from users' access to such Web sites.</li>
                  <li className="no-list no-btm-spacer">Links In — Unless otherwise set forth in a written agreement between you and Purdue, you must adhere to Purdue's linking policy as follows: i) any link to a Purdue Site must be a text only link clearly marked with the name of the specific Purdue Web site, ii) the appearance, position and other aspects of the link may not be such as to damage or dilute the goodwill associated with Purdue's name or trademarks, iii) the link must point to one of the specific Purdue Web site URLs homepage and not to other pages within the Web site, iv) the appearance, position and other attributes of the link may not create the false appearance that your organization or entity is sponsored by, associated with, or affiliated with Purdue or the Site, v) when selected by the user, the link must display the Web site on full screen and not within a "frame" on the linking Website, and vi) the linked Web site must comply with all applicable U.S. laws, rules, and regulations. Purdue shall not be liable for any damages or injury arising from such Links In any Purdue Site.</li>
                </ol>
              </li>
            </ol>
          </Col>
        </Row>
      </section>
    </PatientLayout>
  );
};

export default TermsAndConditionsPage;

export const Head = () => <Seo title="Terms" description="Find the full Terms & Conditions information for Hysingla ER Extended-Release Tablets CII patients. See Full PI, Medication Guide & Boxed Warning." />;
